@tailwind base;
@font-face {
  font-family: 'Pretendard Variable';
  src: url('../public/fonts/PretendardVariable.woff2') format('woff');
  font-weight: 45 920;
  font-style: normal;
  font-display: swap;
}
@tailwind components;
@tailwind utilities;

html,
body {
  position: fixed;
  overflow: hidden;
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: 100vh;
    max-height: -webkit-fill-available !important;
  }
}
